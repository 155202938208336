import { defineMessages } from "react-intl";

export const messages = defineMessages({
  emptyText: {
    id: "SgFvfu",
    defaultMessage: "No Contact Us Forms found",
  },
  contactUsForms: {
    id: "g66Nxg",
    defaultMessage: "Contact Us Forms",
  },
  email: {
    id: "6dLygs",
    defaultMessage: "Contact Us Form Email",
  },
  fullname: {
    id: "v4G7Ye",
    defaultMessage: "Contact Us Form Full Name",
  },
  isClosed: {
    id: "dZNG7+",
    defaultMessage: "Contact Us Form Is Closed status",
  },
  subject: {
    id: "t9Jpdj",
    defaultMessage: "Contact Us Form Subject",
  },
  message: {
    id: "BnIL2w",
    defaultMessage: "Contact Us Form Message",
  },
  dateCreated: {
    id: "sQfdxA",
    defaultMessage: "Contact Us Form Date Created",
  },
});

export const columnsMessages = defineMessages({
  fullname: {
    id: "OOR9ZM",
    defaultMessage: "Full Name",
    description: "customer full name",
  },
  isClosed: {
    id: "DbSygd",
    defaultMessage: "Is Closed",
    description: "contact us form is closed",
  },
  subject: {
    id: "C+ttd8",
    defaultMessage: "Subject",
    description: "contact us form subject",
  },
  message: {
    id: "9/vQhw",
    defaultMessage: "Message",
    description: "contact us form message",
  },
  email: {
    id: "WcoDyx",
    defaultMessage: "Email",
    description: "customer email",
  },
  dateCreated: {
    id: "br8X77",
    defaultMessage: "Date Created",
    description: "contact us form created at",
  },
});

export const categoryMetaGroups = defineMessages({
  attribute: {
    id: "S89Xv5",
    defaultMessage: "Attributes",
    description: "contact us form dynamic column description",
  },
});
