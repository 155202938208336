import { FilterElement } from "@dashboard/components/Filter";
import {
  ContactUsFormFilterKeys,
  ContactUsFormListFilterOpts,
} from "@dashboard/contactUsForms/components/ContactUsFormListPage/filters";
import {
  ContactUsFormListFitersWithMultipleValues,
  ContactUsFormListUrlFilters,
  ContactUsFormListUrlFiltersEnum,
  ContactUsFormListUrlQueryParams,
} from "@dashboard/contactUsForms/urls";
import { ContactUsFormFilterInput } from "@dashboard/graphql";
import { parseBoolean } from "@dashboard/misc";
import {
  createFilterTabUtils,
  createFilterUtils,
  getGteLteVariables,
  getMinMaxQueryParam,
  getSingleValueQueryParam,
} from "@dashboard/utils/filters";

export const CONTACT_US_FORMS_LIST_FILTERS_KEY =
  "shopAndGoOrderListFiltersPresets";

export function getFilterOpts(
  params: ContactUsFormListUrlFilters,
): ContactUsFormListFilterOpts {
  return {
    isClosed: {
      active: params?.isClosed !== undefined,
      value: parseBoolean(params?.isClosed, true),
    },    
    dateCreated: {
      active: [params?.createdFrom, params?.createdTo].some(
        field => field !== undefined,
      ),
      value: {
        max: params?.createdTo || "",
        min: params?.createdFrom || "",
      },
    },
  };
}

export function getFilterQueryParam(
  filter: FilterElement<ContactUsFormFilterKeys>,
): ContactUsFormListUrlFilters {
  const { name } = filter;

  // TODO add other filter params
  switch (name) {
    case ContactUsFormFilterKeys.isClosed:
      return getSingleValueQueryParam(
        filter,
        ContactUsFormListUrlFiltersEnum.isClosed,
      );

    case ContactUsFormFilterKeys.dateCreated:
      return getMinMaxQueryParam(
        filter,
        ContactUsFormListUrlFiltersEnum.createdFrom,
        ContactUsFormListUrlFiltersEnum.createdTo,
      );
  }
}

export const storageUtils = createFilterTabUtils<string>(
  CONTACT_US_FORMS_LIST_FILTERS_KEY,
);
export const { areFiltersApplied, getActiveFilters, getFiltersCurrentTab } =
  createFilterUtils<
    ContactUsFormListUrlQueryParams,
    ContactUsFormListUrlFilters
  >({
    ...ContactUsFormListUrlFiltersEnum,
    ...ContactUsFormListFitersWithMultipleValues,
  });

export function getFilterVariables(
  params: ContactUsFormListUrlFilters,
): ContactUsFormFilterInput {
  return {
    created: getGteLteVariables({
      gte: params.createdFrom,
      lte: params.createdTo,
    }),
    search: params.query,
  };
}
