// @ts-strict-ignore
import {
  dateCell,
  readonlyTextCell,
  textCell,
} from "@dashboard/components/Datagrid/customCells/cells";
import { GetCellContentOpts } from "@dashboard/components/Datagrid/Datagrid";
import { AvailableColumn } from "@dashboard/components/Datagrid/types";
import { ContactUsFormListUrlSortField } from "@dashboard/contactUsForms/urls";
import { ListContactUsFormsQuery } from "@dashboard/graphql";
import { RelayToFlat, Sort } from "@dashboard/types";
import { getColumnSortDirectionIcon } from "@dashboard/utils/columns/getColumnSortDirectionIcon";
import { GridCell, Item } from "@glideapps/glide-data-grid";
import { IntlShape } from "react-intl";

import { columnsMessages } from "./messages";

export const contactUsFormsListStaticColumnAdapter = (
  emptyColumn: AvailableColumn,
  intl: IntlShape,
  sort: Sort<ContactUsFormListUrlSortField>,
) =>
  [
    emptyColumn,
    {
      id: "id",
      title: "ID",
      width: 200,
    },
    {
      id: "email",
      title: intl.formatMessage(columnsMessages.email),
      width: 200,
    },
    {
      id: "fullname",
      title: intl.formatMessage(columnsMessages.fullname),
      width: 200,
    },
    {
      id: "subject",
      title: intl.formatMessage(columnsMessages.subject),
      width: 150,
    },
    {
      id: "message",
      title: intl.formatMessage(columnsMessages.message),
      width: 150,
    },
    {
      id: "isClosed",
      title: intl.formatMessage(columnsMessages.isClosed),
      width: 100,
    },
    {
      id: "dateCreated",
      title: intl.formatMessage(columnsMessages.dateCreated),
      width: 150,
    },
  ].map(column => ({
    ...column,
    icon: getColumnSortDirectionIcon(sort, column.id),
  }));

interface GetCellContentProps {
  columns: AvailableColumn[];
  contactUsForms: RelayToFlat<ListContactUsFormsQuery["contactUsForms"]>;
}

function getDatagridRowDataIndex(row, removeArray) {
  return row + removeArray.filter(r => r <= row).length;
}

export const useGetCellContent = ({
  columns,
  contactUsForms,
}: GetCellContentProps) => {
  return (
    [column, row]: Item,
    { added, removed }: GetCellContentOpts,
  ): GridCell => {
    const columnId = columns[column]?.id;

    if (!columnId) {
      return readonlyTextCell("");
    }

    const rowData = added.includes(row)
      ? undefined
      : contactUsForms[getDatagridRowDataIndex(row, removed)];

    switch (columnId) {
      case "id":
        return readonlyTextCell(atob(rowData.id).split(":")[1]);
      case "email":
        return readonlyTextCell(rowData.email);
      case "fullname":
        return readonlyTextCell(rowData.fullname);
      case "subject":
        return readonlyTextCell(rowData.subject);
      case "message":
        return readonlyTextCell(rowData.message);
      case "isClosed":
        return readonlyTextCell(rowData.isClosed ? "True" : "False");
      case "dateCreated":
        return getDateCellContent(rowData);
      default:
        return textCell("");
    }
  };
};

export function getDateCellContent(
  rowData: RelayToFlat<ListContactUsFormsQuery["contactUsForms"]>[number],
) {
  return dateCell(rowData?.dateCreated);
}
