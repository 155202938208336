import CardTitle from "@dashboard/components/CardTitle";
import FormSpacer from "@dashboard/components/FormSpacer";
import { ContactUsFormErrorFragment } from "@dashboard/graphql";
import { commonMessages } from "@dashboard/intl";
import {
  Card,
  CardContent,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";

import { ContactUsFormUpdateData } from "../ContactUsFormUpdatePage/form";

interface ContactUsFormDetailsFormProps {
  data: ContactUsFormUpdateData;
  disabled: boolean;
  errors: ContactUsFormErrorFragment[];
  onChange: (event: React.ChangeEvent<any>) => void;
}

export const ContactUsFormDetailsForm: React.FC<
  ContactUsFormDetailsFormProps
> = ({ data, onChange }) => {
  const intl = useIntl();
  return (
    <Card>
      <CardTitle
        title={intl.formatMessage(commonMessages.generalInformations)}
      />
      <CardContent>
        <div>
          <TextField
            label="Email"
            name="email"
            value={data && data.email}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        <FormSpacer />
        <div>
          <TextField
            label="Full Name"
            name="fullname"
            value={data && data.fullname}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        <FormSpacer />        
        <FormSpacer />
        <div>
          <TextField
            label="Subject"
            name="subject"
            value={data && data.subject}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        <FormSpacer />
        <div>
          <TextField
            label="Message"
            name="message"
            value={data && data.message}
            fullWidth
            multiline
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        <FormSpacer />
        <div>
          <Select
            name="isClosed"
            value={data && data.isClosed}
            label="Is Closed"
            fullWidth
            onChange={onChange}
          >
            <MenuItem value={false as any}>Open</MenuItem>
            <MenuItem value={true as any}>Closed</MenuItem>
          </Select>
        </div>
        <FormSpacer />
      </CardContent>
    </Card>
  );
};

export default ContactUsFormDetailsForm;
