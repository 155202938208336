import { TopNav } from "@dashboard/components/AppLayout";
import { ListFilters } from "@dashboard/components/AppLayout/ListFilters";
import { FilterPresetsSelect } from "@dashboard/components/FilterPresetsSelect";
import { ListPageLayout } from "@dashboard/components/Layouts";
import { contactUsFormMessages } from "@dashboard/contactUsForms/messages";
import {
  ContactUsFormListUrlQueryParams,
  ContactUsFormListUrlSortField,
  contactUsFormUrl,
} from "@dashboard/contactUsForms/urls";
import { ListContactUsFormsQuery } from "@dashboard/graphql";
import { sectionNames } from "@dashboard/intl";
import {
  FilterPageProps,
  PageListProps,
  RelayToFlat,
  SortPage,
} from "@dashboard/types";
import { Card } from "@material-ui/core";
import { Box, ChevronRightIcon } from "@saleor/macaw-ui-next";
import React, { useState } from "react";
import { useIntl } from "react-intl";

import { ContactUsFormListDatagrid } from "../ContactUsFormListDatagrid";
import {
  ContactUsFormFilterKeys,
  ContactUsFormListFilterOpts,
  createFilterStructure,
} from "./filters";

export interface ContactUsFormListPageProps
  extends PageListProps,
    Omit<
      FilterPageProps<ContactUsFormFilterKeys, ContactUsFormListFilterOpts>,
      "onTabDelete"
    >,
    SortPage<ContactUsFormListUrlSortField> {
  contactUsForms: RelayToFlat<ListContactUsFormsQuery["contactUsForms"]>;
  hasPresetsChanged: boolean;
  onSettingsOpen: () => void;
  onAdd: () => void;
  params: ContactUsFormListUrlQueryParams;
  onTabUpdate: (tabName: string) => void;
  onTabDelete: (tabIndex: number) => void;
}

const ContactUsFormListPage: React.FC<ContactUsFormListPageProps> = ({
  initialSearch,
  filterOpts,
  onAdd,
  onSearchChange,
  onSettingsOpen,
  onFilterChange,
  params,
  onTabChange,
  onTabDelete,
  onTabSave,
  onTabUpdate,
  tabs,
  onAll,
  currentTab,
  hasPresetsChanged,
  ...listProps
}) => {
  const intl = useIntl();
  const filterStructure = createFilterStructure(intl, filterOpts);
  const [isFilterPresetOpen, setFilterPresetOpen] = useState(false);

  return (
    <ListPageLayout>
      <TopNav
        title={intl.formatMessage(sectionNames.contactUs)}
        withoutBorder
        isAlignToRight={false}
      >
        <Box
          __flex={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex">
            <Box marginX={3} display="flex" alignItems="center">
              <ChevronRightIcon />
            </Box>

            <FilterPresetsSelect
              presetsChanged={hasPresetsChanged}
              onSelect={onTabChange}
              onRemove={onTabDelete}
              onUpdate={onTabUpdate}
              savedPresets={tabs}
              activePreset={currentTab}
              onSelectAll={onAll}
              onSave={onTabSave}
              isOpen={isFilterPresetOpen}
              onOpenChange={setFilterPresetOpen}
              selectAllLabel={intl.formatMessage(
                contactUsFormMessages.filterPresetsAll,
              )}
            />
          </Box>
        </Box>
      </TopNav>
      <Card>
        <ListFilters
          initialSearch={initialSearch}
          onFilterChange={onFilterChange}
          onSearchChange={onSearchChange}
          filterStructure={filterStructure}
          searchPlaceholder={intl.formatMessage({
            id: "MHfxgM",
            defaultMessage: "Search forms by name/email",
          })}
        />
        <ContactUsFormListDatagrid
          {...listProps}
          hasRowHover={!isFilterPresetOpen}
          rowAnchor={contactUsFormUrl}
        />
      </Card>
    </ListPageLayout>
  );
};

ContactUsFormListPage.displayName = "ContactUsFormListPage";
export default ContactUsFormListPage;
