// @ts-strict-ignore
import DeleteFilterTabDialog from "@dashboard/components/DeleteFilterTabDialog";
import SaveFilterTabDialog from "@dashboard/components/SaveFilterTabDialog";
import ContactUsFormListPage from "@dashboard/contactUsForms/components/ContactUsFormListPage/ContactUsFormListPage";
import {
  contactUsFormListUrl,
  ContactUsFormListUrlDialog,
  ContactUsFormListUrlQueryParams,
  contactUsFormSettingsPath,
} from "@dashboard/contactUsForms/urls";
import { useListContactUsFormsQuery } from "@dashboard/graphql";
import { useFilterHandlers } from "@dashboard/hooks/useFilterHandlers";
import { useFilterPresets } from "@dashboard/hooks/useFilterPresets";
import useListSettings from "@dashboard/hooks/useListSettings";
import useNavigator from "@dashboard/hooks/useNavigator";
import { usePaginationReset } from "@dashboard/hooks/usePaginationReset";
import usePaginator, {
  createPaginationState,
  PaginatorContext,
} from "@dashboard/hooks/usePaginator";
import { ListViews } from "@dashboard/types";
import createDialogActionHandlers from "@dashboard/utils/handlers/dialogActionHandlers";
import createSortHandler from "@dashboard/utils/handlers/sortHandler";
import { mapEdgesToItems } from "@dashboard/utils/maps";
import { getSortParams } from "@dashboard/utils/sort";
import React from "react";

import {
  getFilterOpts,
  getFilterQueryParam,
  getFilterVariables,
  storageUtils,
} from "./filters";
import { DEFAULT_SORT_KEY, getSortQueryVariables } from "./sort";

interface ContactUsFormListProps {
  params: ContactUsFormListUrlQueryParams;
}

export const ContactUsFormList: React.FC<ContactUsFormListProps> = ({
  params,
}) => {
  const navigate = useNavigator();
  const { updateListSettings, settings } = useListSettings(
    ListViews.CONTACT_US_FORMS,
  );
  const {
    hasPresetsChanged,
    onPresetChange,
    onPresetDelete,
    onPresetSave,
    onPresetUpdate,
    getPresetNameToDelete,
    presets,
    selectedPreset,
    setPresetIdToDelete,
  } = useFilterPresets({
    params,
    getUrl: contactUsFormListUrl,
    storageUtils,
    reset: () => "",
  });

  usePaginationReset(contactUsFormListUrl, params, settings.rowNumber);

  const [changeFilters, resetFilters, handleSearchChange] = useFilterHandlers({
    createUrl: contactUsFormListUrl,
    getFilterQueryParam,
    params,
    defaultSortField: DEFAULT_SORT_KEY,
    hasSortWithRank: false,
    keepActiveTab: true,
  });

  const [openModal, closeModal] = createDialogActionHandlers<
    ContactUsFormListUrlDialog,
    ContactUsFormListUrlQueryParams
  >(navigate, contactUsFormListUrl, params);

  const paginationState = createPaginationState(settings.rowNumber, params);

  const queryVariables = React.useMemo(
    () => ({
      ...paginationState,
      filter: getFilterVariables(params),
      sort: getSortQueryVariables(params),
    }),
    [params, settings.rowNumber],
  );
  const { data, loading } = useListContactUsFormsQuery({
    displayLoader: true,
    variables: queryVariables,
  });

  const paginationValues = usePaginator({
    pageInfo: data?.contactUsForms?.pageInfo,
    paginationState,
    queryString: params,
  });

  const handleSort = createSortHandler(navigate, contactUsFormListUrl, params);

  return (
    <PaginatorContext.Provider value={paginationValues}>
      <ContactUsFormListPage
        settings={settings}
        currentTab={selectedPreset}
        disabled={loading}
        filterOpts={getFilterOpts(params)}
        contactUsForms={mapEdgesToItems(data?.contactUsForms)}
        sort={getSortParams(params)}
        onAdd={() => openModal("create-contact-us-form")}
        onUpdateListSettings={updateListSettings}
        onSort={handleSort}
        onSearchChange={handleSearchChange}
        onFilterChange={changeFilters}
        onTabSave={() => openModal("save-search")}
        onTabDelete={(tabIndex: number) => {
          setPresetIdToDelete(tabIndex);
          openModal("delete-search");
        }}
        onTabChange={onPresetChange}
        onTabUpdate={onPresetUpdate}
        initialSearch={params.query || ""}
        tabs={presets.map(tab => tab.name)}
        onAll={resetFilters}
        onSettingsOpen={() => navigate(contactUsFormSettingsPath)}
        params={params}
        hasPresetsChanged={hasPresetsChanged()}
      />
      <SaveFilterTabDialog
        open={params.action === "save-search"}
        confirmButtonState="default"
        onClose={closeModal}
        onSubmit={onPresetSave}
      />
      <DeleteFilterTabDialog
        open={params.action === "delete-search"}
        confirmButtonState="default"
        onClose={closeModal}
        onSubmit={onPresetDelete}
        tabName={getPresetNameToDelete()}
      />
    </PaginatorContext.Provider>
  );
};

export default ContactUsFormList;
