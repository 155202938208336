import { stringifyQs } from "@dashboard/utils/urls";
import urlJoin from "url-join";

import {
  ActiveTab,
  BulkAction,
  Dialog,
  Filters,
  FiltersWithKeyValueValues,
  Pagination,
  Sort,
  TabActionDialog,
} from "../types";

const contactUsFormSectionUrl = "/contact-us-forms/";

export const contactUsFormListPath = contactUsFormSectionUrl;
export enum ContactUsFormListUrlFiltersEnum {
  createdFrom = "createdFrom",
  createdTo = "createdTo",
  email = "email",
  fullname = "fullname",
  isClosed = "isClosed",
  dateCreated = "dateCreated",
  query = "query",
}

export enum ContactUsFormListFitersWithMultipleValues {
  selectedStore = "selectedStore",
}
export type ContactUsFormListUrlFilters =
  Filters<ContactUsFormListUrlFiltersEnum> &
    FiltersWithKeyValueValues<ContactUsFormListFitersWithMultipleValues>;

export enum ContactUsFormListUrlSortField {
  id = "id",
  email = "email",
  fullname = "fullname",
  subject = "subject",
  dateCreated = "dateCreated",
  isClosed = "isClosed",
  message = "message",
}

export type ContactUsFormListUrlSort = Sort<ContactUsFormListUrlSortField>;
export type ContactUsFormListUrlQueryParams = BulkAction &
  Dialog<ContactUsFormListUrlDialog> &
  ContactUsFormListUrlFilters &
  ContactUsFormListUrlSort &
  Pagination &
  ActiveTab;

export const contactUsFormUrl = (
  id: string,
  params?: ContactUsFormListUrlQueryParams,
) => contactUsFormPath(encodeURIComponent(id)) + "?" + stringifyQs(params);

export const contactUsFormListUrl = (
  params?: ContactUsFormListUrlQueryParams,
): string => {
  const ContactUsFormList = contactUsFormListPath;
  if (params === undefined) {
    return ContactUsFormList;
  } else {
    return urlJoin(ContactUsFormList, "?" + stringifyQs(params));
  }
};

export type ContactUsFormUrlDialog = "delete" | "delete-contact-us-form";
export type ContactUsFormUrlQueryParams = BulkAction &
  Dialog<ContactUsFormUrlDialog>;
export const contactUsFormPath = (id: string) =>
  urlJoin(contactUsFormSectionUrl + id);

export type ContactUsFormListUrlDialog =
  | "delete"
  | "export"
  | "create-contact-us-form"
  | TabActionDialog;

export const contactUsFormSettingsPath = urlJoin(
  contactUsFormSectionUrl,
  "settings",
);
