import { TopNav } from "@dashboard/components/AppLayout";
import CardSpacer from "@dashboard/components/CardSpacer";
import { ConfirmButtonTransitionState } from "@dashboard/components/ConfirmButton";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import Savebar from "@dashboard/components/Savebar";
import { contactUsFormListUrl } from "@dashboard/contactUsForms/urls";
import {
  ContactUsFormDetailsQuery,
  ContactUsFormErrorFragment,
} from "@dashboard/graphql";
import { SubmitPromise } from "@dashboard/hooks/useForm";
import useNavigator from "@dashboard/hooks/useNavigator";
import { ListProps, ListViews } from "@dashboard/types";
import React from "react";

import ContactUsFormDetailsForm from "../ContactUsFormDetailsForm";
import ContactUsFormUpdateForm, { ContactUsFormUpdateData } from "./form";

export enum ContactUsFormPageTab {
  contactUsForm = "contact us forms",
}

export interface ContactUsFormUpdatePageProps
  extends Pick<
    ListProps<ListViews.CONTACT_US_FORMS>,
    "onUpdateListSettings" | "settings"
  > {
  errors: ContactUsFormErrorFragment[];
  disabled: boolean;
  contactUsForm: ContactUsFormDetailsQuery["contactUsForm"];
  saveButtonBarState: ConfirmButtonTransitionState;
  onSubmit: (data: ContactUsFormUpdateData) => SubmitPromise;
}

export const ContactUsFormUpdatePage: React.FC<
  ContactUsFormUpdatePageProps
> = ({
  errors,
  disabled,
  contactUsForm,
  saveButtonBarState,
  onSubmit,
}: ContactUsFormUpdatePageProps) => {
  const navigate = useNavigator();
  const backHref = contactUsFormListUrl();

  return (
    <ContactUsFormUpdateForm
      contactUsForm={contactUsForm}
      onSubmit={onSubmit}
      disabled={disabled}
    >
      {({ data, change, submit, isSaveDisabled }) => (
        <DetailPageLayout gridTemplateColumns={1}>
          <TopNav href={backHref} title={"Order #" + contactUsForm?.id} />
          <DetailPageLayout.Content>
            <ContactUsFormDetailsForm
              data={data}
              disabled={disabled}
              errors={errors}
              onChange={change}
            />
            <CardSpacer />
            <Savebar
              onCancel={() => navigate(backHref)}
              onSubmit={submit}
              state={saveButtonBarState}
              disabled={isSaveDisabled}
            />
          </DetailPageLayout.Content>
        </DetailPageLayout>
      )}
    </ContactUsFormUpdateForm>
  );
};

ContactUsFormUpdatePage.displayName = "ContactUsFormUpdatePage";
export default ContactUsFormUpdatePage;
