// @ts-strict-ignore
import { IFilter } from "@dashboard/components/Filter";
import { PermissionEnum } from "@dashboard/graphql";
import { FilterOpts, MinMax } from "@dashboard/types";
import {
  createBooleanField,
  createDateField,
} from "@dashboard/utils/filters/fields";
import { defineMessages, IntlShape } from "react-intl";

export enum ContactUsFormFilterKeys {
  dateCreated = "dateCreated",
  isClosed = "isClosed",
}

export interface ContactUsFormListFilterOpts {
  dateCreated: FilterOpts<MinMax>;
  isClosed: FilterOpts<boolean>;
}

const messages = defineMessages({
  dateCreated: {
    id: "66JlXG",
    defaultMessage: "Date Created",
    description: "contact us form date created",
  },
  isClosed: {
    id: "o1UZx5",
    defaultMessage: "Order Closed",
    description: "contact us form is closed",
  },
});

const contactUsFormMessages = defineMessages({
  positive: {
    id: "mg+oCj",
    defaultMessage: "Form Open",
    description: "contact us form open",
  },
  negative: {
    id: "yZoP/8",
    defaultMessage: "Form Closed",
    description: "contact us form closed",
  },
});

export function createFilterStructure(
  intl: IntlShape,
  opts: ContactUsFormListFilterOpts,
): IFilter<ContactUsFormFilterKeys> {
  return [
    {
      ...createDateField(
        ContactUsFormFilterKeys.dateCreated,
        intl.formatMessage(messages.dateCreated),
        opts.dateCreated.value,
      ),
      active: opts.dateCreated.active,
    },
    {
      ...createBooleanField(
        ContactUsFormFilterKeys.isClosed,
        intl.formatMessage(messages.isClosed),
        opts.isClosed.value,
        {
          positive: intl.formatMessage(contactUsFormMessages.positive),
          negative: intl.formatMessage(contactUsFormMessages.negative),
        },
      ),
      active: opts.isClosed.active,
      permissions: [PermissionEnum.MANAGE_CONTACT_US_FORMS],
    },
  ];
}
